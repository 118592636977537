import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import { rideEndedDetails } from '../../../redux/actions'
/**
 *Initiation function for the RideDetails component
 */
const RideDetails = ({ match, email, rideEndedDetails }) => {
    // longitude is a number variable that contains the value of the longitude input from the user      
    const [longitude, setlongitude] = useState("")
    // latitude is a number variable that contains the value of the latitude input from the user      
    const [latitude, setlatitude] = useState("")
    // battery is a number variable that contains the value of the battery input from the user      
    const [battery, setbattery] = useState("")
    // userinfo is the state we use to store ride details
    const [usersinfo, setusersinfo] = useState("")
    // id is a number variable that contains the value of the id for the current ride    
    const [id, setid] = useState("")
    // batteryValidate is a boolean variable that contains the battery value check     
    const [batteryValidate, setbatteryValidate] = useState("")
    // latValidate is a boolean variable that contains the latitude value check     
    const [latValidate, setlatValidate] = useState("")
    // longValidate is a boolean variable that contains the longitude value check   
    const [longValidate, setlongValidate] = useState("")
    // bat is a boolean variable that verifies data in a the battery input       
    const [bat, setbat] = useState(false)
    // lat is a boolean variable that verifies data in a the latitude input       
    const [lat, setlat] = useState(false)
    // long is a boolean variable that verifies data in a the longitude input    
    const [long, setlong] = useState(false)
    // req is a boolean variable that verifies data in all the input fields.      
    const [req, setreq] = useState(false)

    /**
    * The UseEffect function is used when we want to gather the ride details of a users ride.   
    */
    useEffect(() => {
        // provides time for start ride request to complete before requesting active ride details.
        setTimeout(() => {
            try {
                // http request that displays the currently selected rides details, using the vehicle id and the email thats saved in session storages.
                axios.get("/api/rides/active?vehicle_id=" + match.params.id + "&email=" + sessionStorage.getItem("user"))
                    .then(function (response) {
                        // setting user response data inside userinfo state using the setstate method.                    
                        setusersinfo(response.data)
                        setid(response.data.id)
                    })
            } catch (e) {

            }
        }, 1000)
        // the watcher on these dependencies trigger the component to update.   
    }, [lat, long, bat, batteryValidate, longValidate, latValidate])


    /**
     * batteryNumber function will run to check user input
     *
     * @param  {object} e is the argument of the event handler attached to the onChange event of the battery input field.
     */
    const batteryNumber = (e) => {
        setbattery(e.target.value)
        // running check to see if value if accepted. e.target.value is the value the text entered in the battery input.        
        if (isNaN(e.target.value) || e.target.value == "" || e.target.value > 100 || e.target.value < 0 || e.target.value.includes(".")) {
            setbat(false)
        } else {
            setbat(true)
            setbatteryValidate(true)
        }
    }

    /**
     * latNumber function will run to check user input
     *
     * @param  {object} e is the argument of the event handler attached to the onChange event of the latitude input field.
    */
    const latNumber = (e) => {
        setlatitude(e.target.value)
        // running check to see if value if accepted. e.target.value is the value the text entered in the latitude input.        
        if (isNaN(e.target.value) || e.target.value == "" || e.target.value > 90 || e.target.value < -90) {
            setlat(false)
        } else {
            setlat(true)
            setlatValidate(true)
        }
    }

    /**
     * longNumber function will run to check user input
     *
     * @param  {object} e is the argument of the event handler attached to the onChange event of the longitude input field.
    */
    const longNumber = (e) => {
        setlongitude(e.target.value)
        // running check to see if value if accepted. e.target.value is the value the text entered in the longitude input.
        if (isNaN(e.target.value) || e.target.value == "" || e.target.value > 180 || e.target.value < -180) {
            setlong(false)
        } else {
            setlong(true)
            setlongValidate(true)
        }
    }


    /**
     * This is click event handler that ends rides. It is triggered by the end ride button.
    */
    const EndRide = () => {
        // running check to see if value if accepted.
        if (!long) {
            setlongValidate(false)
        }
        if (!lat) {
            setlatValidate(false)
        }

        if (!bat) {
            setbatteryValidate(false)
        }

        if (long && lat && bat) {
            try {
                // http request to end ride which takes the user email, vehicle id, longitude, latitude, and battery useStates.
                setreq(true)
                axios.post(`/api/rides/end`, {
                    email: email,
                    vehicle_id: id,
                    longitude: longitude,
                    latitude: latitude,
                    battery: Number(battery)
                })
                    .then(function (response) {
                        // The response is sent to redux as a array and is provided as a toast message if successful.
                        rideEndedDetails(response.data.messages)
                    })
                    .catch(function (error) {

                    });
            } catch (e) {

            }
        }
    }

    return !usersinfo ? null : (
        <div>

            <h1 className="text-center" style={{ marginTop: "30px", fontWeight: "600" }}>Riding a {usersinfo.vehicle_info.type}</h1>


            <div className="container col-md-6" style={{ marginRight: "35.8%" }}>
                {match.params.view != "true" ? <Alert key={1} variant={'warning'}>
                    Ride started with vehicle {match.params.id}
                </Alert> : null}
                <h5 style={{ fontWeight: "500", fontSize: "22px", color: 'black' }}>Vehicle ID: {usersinfo.id}</h5>

                <div style={{ display: 'flex', justifyContent: 'space-between', width: "75%" }}>
                    <div className="text-left">
                        <div>
                            <p className="card-text">Start Time</p>
                        </div>
                        <div>
                            <p className="card-text">{usersinfo.last_checkin}</p>
                        </div>
                    </div>

                    <div className="text-left">
                        <div>
                            <p className="card-text">Longitude</p>
                        </div>
                        <div>
                            <p className="card-text">{usersinfo.last_longitude}</p>
                        </div>
                    </div>

                    <div className="text-left">
                        <div>
                            <p className="card-text">Latitude</p>
                        </div>
                        <div>
                            <p className="card-text">{usersinfo.last_latitude}</p>
                        </div>
                    </div>
                    <div className="text-left">
                        <div>
                            <p className="card-text">Battery</p>
                        </div>
                        <div>
                            <p className="card-text">{usersinfo.battery}%</p>
                        </div>
                    </div>
                    <input type="hidden" value={usersinfo.id} />
                </div>

                <div style={{ display: "flex", justifyContent: 'start', alignItems: "center", marginTop: "30px", marginBottom: "90px", lineHeight: "1.5" }}>
                    <div>
                        <form style={{ width: "600px", justifyContent: "stretch", border: "1px solid #E9E9E9", padding: "15px" }}>
                            <p>When you're finished riding, please input the following and end your ride:</p>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Longitude</label>
                                <input style={{ height: "calc(1.5em + 1.125rem + 2px)" }} type="email" onChange={longNumber} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                {longValidate === false ? <div><p>Longitude must be between -180 and 180.</p></div> : null}
                            </div>

                            <div class="form-group">
                                <label for="exampleInputEmail1">Latitude</label>
                                <input style={{ height: "calc(1.5em + 1.125rem + 2px)" }} type="text" onChange={latNumber} class="form-control" id="firstname" aria-describedby="password" />
                                {latValidate === false ? <div><p>Latitude must be between -90 and 90.</p></div> : null}
                            </div>

                            <div class="form-group">
                                <label for="exampleInputEmail1">Battery (percent)</label>
                                <input style={{ height: "calc(1.5em + 1.125rem + 2px)" }} type="text" required onChange={batteryNumber} class="form-control" id="lastname" aria-describedby="password" />
                                {batteryValidate === false ? <div><p>Not a valid integer value</p><p>Battery (percent) must be between 0 and 100</p></div> : null}
                            </div>

                            <Link to={req ? `/vehicledetail/${usersinfo.id}` : '#'} onMouseDown={EndRide} type="button" class="btn btn-danger form-control" style={{ height: "calc(1.5em + 1.125rem + 2px)", backgroundColor: '#FF3565' }}>End Ride</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}



/**
 * Maps data from the Redux state management object for use on this page.
 *
 * @param  {object} state The Redux state object that contains persisted state data. 
 * @returns {object} The mapped state data as an object that is passed into the page initialization function.
 */
const mapStateToProps = (state) => ({
    vehicles: state.data.vehicles,
    email: state.data.email
})

export default connect(mapStateToProps, { rideEndedDetails })(RideDetails)
