import React, { useEffect, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { startRides, resetDash } from "../../../redux/actions";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

/**
 * Initiation function for the Dashboard component
 */
function Dashboard({ email, vehicleID, resetDash }) {
  // vehicleList is a array variable that contains the user data from the API call
  const [vehicleList, setvehicleList] = useState([]);
  // id is a string variable that contains the value of the vehicle Id that is selected
  const [id, setid] = useState("");
  // deletedVehicle is a boolan variable to returns true or false to open a toast message when a vehicle is deleted.
  const [deletedVehicle, setdeletedVehicle] = useState("");

  /**
   * This useEffect acts as a watcher that checks for component updates/mounts/unmounts
   */
  useEffect(() => {
    // axios is a http library used to retrieve data from APIs. Currently getting and populating the vehicle list
    axios
      .get("/api/vehicles?max_vehicles=20")
      .then(function (response) {
        // populating vehicleList state with data
        setvehicleList(response.data);
        if (vehicleID != null) {
          // removing toast message display about vehicle being deleted
          setdeletedVehicle(true);
        } else {
          // removing toast message about vehicle being deleted
          setdeletedVehicle(false);
        }
      })
      .catch(function (error) {});
    // watching for any changes in vehicleID
  }, [vehicleID]);

  /**
   * This is click event handler that starts a new ride for the current user.  It is triggered by start ride button.
   */
  const startRide = () => {
    // http request to the backend to start ride with required email and vehicle_id
    axios
      .post("/api/rides/start", {
        email: email,
        vehicle_id: id,
      })
      .then(function (response) {
        console.log("Email : " + email);
      })
      .catch(function (error) {});
  };

  return vehicleList.length == 0 ? null : (
    <div
      className="container"
      style={{ paddingLeft: "0px", paddingRight: "0px" }}>
      <h1 style={{ margin: "20px", textAlign: "center" }}>Vehicles</h1>
      <Alert
        color="warning"
        style={{ padding: "20px" }}
        isOpen={deletedVehicle}>
        Deleted vehicle with id '{vehicleID}' from database.
      </Alert>
      <p className="text-left">
        Below is a list of all* vehicles, their location, and their status.
      </p>
      <div className="row">
        {vehicleList.map((e) => {
          return (
            <div
              key={e.id}
              className="col-lg-4 col-md-6"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
              }}>
              <div className="card" style={{ width: "100%" }}>
                <div className="topboders" style={{ height: "170px" }}></div>
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "40px",
                    }}>
                    <div>
                      <h4 className="card-title text-left text-capitalize">
                        {e.vehicle_info.type}
                      </h4>
                    </div>
                    <div>
                      {!e.in_use ? (
                        <p className="card-title text-right">
                          <span style={{ color: "lightgreen" }}>●</span>{" "}
                          Available
                        </p>
                      ) : (
                        <p className="card-title text-right">
                          <span style={{ color: "#FF8C00" }}>●</span>{" "}
                          Unavailable
                        </p>
                      )}
                    </div>
                  </div>
                  <Link
                    to={`/vehicledetail/${e.id}`}
                    className="btn btn-danger"
                    style={{
                      width: "80%",
                      backgroundColor: "#FF3565",
                      height: "calc(1.5em + 1.125rem + 2px)",
                    }}>
                    See Vehicle
                  </Link>
                  <hr />
                  <p className="text-left">ID</p>
                  <p className="card-text text-left">{e.id}</p>
                  <hr />
                  <div style={{ display: "flex", justifyContent: "start" }}>
                    <div className="text-left">
                      <div>
                        <p className="card-text">Longitude</p>
                      </div>
                      <div>
                        <p className="card-text">
                          {Number.isInteger(e.last_longitude)
                            ? e.last_longitude + ".0"
                            : e.last_longitude}
                        </p>
                      </div>
                    </div>

                    <div className="text-left" style={{ marginLeft: "70px" }}>
                      <div>
                        <p className="card-text">Latitude</p>
                      </div>
                      <div>
                        <p className="card-text text-left">
                          {Number.isInteger(e.last_latitude)
                            ? e.last_latitude + ".0"
                            : e.last_latitude}
                        </p>
                      </div>
                    </div>
                    <input type="hidden" value={e.vehicle_id} />
                  </div>
                  <hr />
                  <div style={{ display: "flex", justifyContent: "start" }}>
                    <div className="text-left">
                      <div>
                        <p className="card-text">Color</p>
                      </div>
                      <div>
                        <p className="card-text text-capitalize">
                          {e.vehicle_info.color}
                        </p>
                      </div>
                    </div>

                    <div className="text-left" style={{ marginLeft: "100px" }}>
                      <div>
                        <p className="card-text text-left">Brand</p>
                      </div>
                      <div>
                        <p className="card-text">
                          {e.vehicle_info.purchase_information.manufacturer}
                        </p>
                      </div>
                    </div>
                    <input type="hidden" value={e.vehicle_id} />
                  </div>
                  <hr />
                  <div style={{ display: "flex", justifyContent: "start" }}>
                    <div className="text-left">
                      <div>
                        <p className="card-text">Battery</p>
                      </div>
                      <div>
                        <p className="card-text">{e.battery}%</p>
                      </div>
                    </div>
                  </div>{" "}
                  <hr />
                  {!e.in_use ? (
                    <Link
                      onClick={startRide}
                      onFocus={() => setid(e.id)}
                      to={`/ridedetail/${e.id}/false`}
                      className="btn btn-danger"
                      style={{
                        width: "100%",
                        backgroundColor: "#FF3565",
                        height: "calc(1.5em + 1.125rem + 2px)",
                      }}>
                      Start ride
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <p>
        * The query is limited to the first 20 vehicles in the table to ensure
        quick rendering, but there may be many more rows in the table.
      </p>
    </div>
  );
}

/**
 * Maps data from the Redux state management object for use on this page.
 *
 * @param  {object} state The Redux state object that contains persisted state data.
 * @returns {object} The mapped state data as an object that is passed into the page initialization function.
 */
const mapStateToProps = (state) => ({
  email: state.data.email,
  vehicleID: state.data.vehicleID,
});

export default connect(mapStateToProps, { startRides, resetDash })(Dashboard);
